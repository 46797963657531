@import '_colours.scss';
@import '_breakpoints.scss';

body {
    margin: 0;
    font-family: Verdana;
    padding:10px;
  }

.content {
  max-width: $desktop;
  margin: auto;
}

h1 {
  font-size: 1.2em;
  text-align: center;
}

.banner {
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contentRow {
  display: block;
  margin-bottom: 10px;
  flex-direction: row;
}

.reverseOrder {
  flex-direction: row-reverse;
}

.contentBlock {
  margin: 10px;
} 

@media screen and (min-width: $desktop) {
  h1 {
    font-size: 2em;
  }.contentRow {
    display: flex;
  }
}