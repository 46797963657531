@import './_colours.scss';
.cardList {
    position: relative;
    display: flex;
    height: 140px;
    margin: auto;
    justify-content: center;  
}
  
.answers {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0px auto 0px auto;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
  
}