@import './_colours.scss';

header {
  width: 100%;
  max-width: 800px;
  height: 50px;
  display: flex;
  margin: auto;
  margin-bottom: 20px;

  .progressBar {
    width: 81%;
    margin: 0 2px;
    background-color: #eee;
    height: 100%;
    position: relative;
    margin-bottom: 10px;   
    
    .progress {
      height: 100%;
      text-align: right;
    }

    .label {
      width: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      align-items: center;
      display: flex;
      text-align: center;
      justify-content: center;
    }

  }

  .cardChangeBtn {
    width: 75px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: $primary-action;
    color: $secondary;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    user-select: none;
  }
  .cardPrevBtn {
    width: 75px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: $cancel-action;
    color: $secondary;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    user-select: none;
    font-size: smaller;
  }
.disabled {
  background-color: $ghosted;
  cursor: not-allowed;
}

  
  .info {
    width: 75px;
    height: auto;
    padding: 13px;
    background-color: $intermediate;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}