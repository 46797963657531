@import '_breakpoints.scss';

.demographicSelects {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .formField {
        width: 100%;

        .demoLabel {
            width: 200px;
            display: inline-block;
            font-weight: bold;
            font-size: 0.8em;
            padding-bottom: 5px;
        }

        .select {
            padding-bottom: 5px;
        }
    }
    @media screen and (min-width: $desktop) {
        .formField {
          width: 48%;
        }
    }
}


.demoFtr {
    margin-top: 20px;
    display: flex;

    .proceed {
        width: 150px;
        height: auto;
        padding: 13px;
    }
}