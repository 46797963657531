@import '../_colours.scss';

.button {
    display: block;
    width: 200px;
    height: 50px;
    background-color: $primary-action;
    text-align: center;
    padding-top: 27px;
    margin: auto;
    cursor: pointer;
    border-radius: 5px;
    color: $secondary;
    text-decoration: none;
  }
  
  .button:visited {
    color: $secondary;
  }